import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import ContactUsForm from "../sections/contact-us/ContactUsForm"
import contactUsHeroBgUrl from "../../media/contact-us-banner-overlay.png"

const contactUsHeroTitle = "Contact Us"
const contactUsHeroDescription = ""

const ContactUsPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title="Contact Us" />
    <Hero
      backgroundImage={contactUsHeroBgUrl}
      title={contactUsHeroTitle}
      description={contactUsHeroDescription}
    />
    <ContactUsForm />
  </Layout>
)

export default ContactUsPage
