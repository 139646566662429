import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
    
  },
  '@global': {
    '.MuiFormControlLabel-root':{
      width:"100%"
    },
    '.MuiFormControlLabel-label': {
      fontSize: '1rem',
      lineHeight: '1.2rem'
    },
    '.MuiFormGroup-row': {
      marginTop: 10
    }
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  description: {
    textAlign: "center",
  },
  formWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#f5f7f6",
    border: "1px solid #b4b4b4",
    padding: 40,
    marginTop: 20,
  },
  form: {
    width: "100%",
    maxWidth: 800,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 30,
  },
  submittedMessage: {
    padding: "10px 30px"
  },
  input: {
    width: "100%",
    margin: 10,
    backgroundColor: "#fff",
    "& > p": {
      backgroundColor: "#f5f7f6",
      margin: 0,
      padding: 5
    }
  },
  checkBoxWrapper:{
    marginTop: 15,
    "& > label":{
      width: "100%",
      display: "block"
    },
    "& > label > span": {
      fontSize: "1rem",
      lineHeight: "1.8rem"
    }
  },
  checkBoxHeading:{
    marginBottom: 10,
    fontSize: "1.25rem"
  },
  submitButton: {
    width: "100%",
    color: "#fff",
    fontSize: 24,
    marginTop: 20,
  },
}))